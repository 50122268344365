import {Redirect, RouteComponentProps} from '@reach/router'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import ACTION_TYPES from 'actionTypes'
import {CanEdit, EditWishes, EditWishListModal} from 'components'
import {Button, Div, H1} from 'elements'
import {getHasSections} from 'utils'
import {WishLists} from '../interfaces'

interface State {
  wishLists: WishLists
}

const EditWishList: React.FC<RouteComponentProps<{id: string}>> = ({id}) => {
  const dispatch = useDispatch()
  const wishList = useSelector((state: State) => {
    if (!id || !state.wishLists || !state.wishLists.items[id]) {
      return null
    }
    return state.wishLists.items[id]
  })
  const [isEditModalOpen, setEditModalOpen] = React.useState(false)

  if (!id || !wishList) {
    return <Redirect noThrow={true} to="lists" />
  }

  const toggleModelOpen = () => {
    setEditModalOpen(!isEditModalOpen)
  }

  const addWish = () => {
    dispatch({
      type: ACTION_TYPES.WISHES__ADD_WISH,
      payload: {
        wishListId: id,
      },
    })
  }

  const saveWishList = () => {
    dispatch({
      type: ACTION_TYPES.WISH_LIST__SAVE,
      payload: {
        wishListId: id,
        wishList,
      },
    })
  }

  const addSection = () => {
    dispatch({
      type: ACTION_TYPES.WISHES__ADD_SECTION,
      payload: {
        wishListId: id,
      },
    })
  }

  const {title, wishes, isSaving} = wishList
  const hasWishes = !!wishes
  const hasSections = hasWishes && getHasSections(wishes)

  return (
    <CanEdit wishList={wishList} redirectTo={`/lists/${id}`}>
      <Div flex={true} justifyContent="space-between" alignItems="center" mb={4}>
        <H1 m={0}>{title}</H1>
        <Button pill={true} onClick={toggleModelOpen}>
          Ret
        </Button>
      </Div>

      {hasWishes && <EditWishes wishListId={id} wishes={wishes} hasSections={hasSections} />}
      <Div mv={4} flex={true} justifyContent="space-between">
        <Div flexGrow={true}>
          <Button type="primary" pill={true} mr={4} onClick={addWish}>
            + Ønske
          </Button>
          <Button pill={true} onClick={addSection}>
            + Sektion
          </Button>
        </Div>
        <Button pill={true} type="primary" disabled={isSaving} onClick={saveWishList}>
          {isSaving ? 'Gemmer...' : 'Gem'}
        </Button>
      </Div>

      <EditWishListModal id={id} isOpen={isEditModalOpen} onClose={toggleModelOpen} />
    </CanEdit>
  )
}

export default EditWishList
