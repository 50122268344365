import {EditWishModal} from 'components'
import {Button, Div, Input} from 'elements'
import React from 'react'
import {useDispatch} from 'react-redux'
import {color} from 'style'
import {getIsSection} from 'utils'
import ACTION_TYPES from '../actionTypes'
import {Wish} from '../interfaces'

interface Props {
  wishListId: string
  wish: Wish
  isFirst: boolean
  isLast: boolean
  hasSections: boolean
}

let isDeleting = false

const EditWish: React.FC<Props> = props => {
  const dispatch = useDispatch()

  const {wishListId, wish, isFirst, isLast} = props
  const {id, title, url, position, isActive} = wish
  const isSection = getIsSection(title)

  const handleKeyDownTitle = (event: KeyboardEvent) => {
    const {key} = event
    switch (key) {
      case 'ArrowUp':
        return (
          !isFirst &&
          dispatch({
            type: ACTION_TYPES.WISHES__MOVE_CURSOR_UP,
            payload: {wishListId, id, position},
          })
        )

      case 'ArrowDown':
        return (
          !isLast &&
          dispatch({
            type: ACTION_TYPES.WISHES__MOVE_CURSOR_DOWN,
            payload: {wishListId, id, position},
          })
        )

      case 'Enter':
        return (
          title !== '' &&
          dispatch({
            type: ACTION_TYPES.WISHES__ADD_WISH,
            payload: {
              wishListId,
              id,
              position,
            },
          })
        )

      case 'Backspace':
        if (title !== '') {
          return
        }
        isDeleting = true
        dispatch({
          type: ACTION_TYPES.WISHES__REMOVE_WISH,
          payload: {
            wishListId,
            id,
            position,
          },
        })
        return window.setTimeout(() => {
          isDeleting = false
        }, 100)

      default:
    }
  }

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDeleting) {
      dispatch({
        type: ACTION_TYPES.WISH__UPDATE_TITLE,
        payload: {
          wishListId,
          id,
          title: event.target.value,
        },
      })
    }
  }

  const handleClickTitle = () => {
    dispatch({
      type: ACTION_TYPES.WISHES__SET_ACTIVE,
      payload: {wishListId, id},
    })
  }

  const removeWish = () => {
    dispatch({
      type: ACTION_TYPES.WISHES__REMOVE_WISH,
      payload: {wishListId, id, position},
    })
  }

  const openModal = () => {
    dispatch({
      type: ACTION_TYPES.WISHES__CLEAR_ACTIVE,
      payload: {wishListId},
    })
    dispatch({
      type: ACTION_TYPES.WISH__OPEN_MODAL,
      payload: {wishListId, id},
    })
  }

  const styles: any = {
    input: {
      fontWeight: isSection ? 700 : 400,
    },
  }

  if (url) {
    styles.input.color = color.primary
  }

  return (
    <Div flex={true} ml={isSection ? 0 : props.hasSections ? 8 : 0}>
      <Div mr={4} pt={1.5}>
        ⋮⋮
      </Div>
      <Div flex={true} column={true} mr={true} style={{width: '100%'}}>
        <Input
          id={id}
          value={title}
          focus={isActive || false}
          focusOnUpdate={isActive || false}
          selectSection={isActive && isSection}
          onKeyDown={handleKeyDownTitle}
          onChange={handleTitleChange}
          onClick={handleClickTitle}
          autoComplete="false"
          style={styles.input}
        />
      </Div>
      <Button mr={true} square={true} onClick={openModal} tabIndex="-1">
        …
      </Button>
      <Button square={true} onClick={removeWish} tabIndex="-1">
        ×
      </Button>

      <EditWishModal wishListId={wishListId} wish={wish} />
    </Div>
  )
}

export default EditWish
