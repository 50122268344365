import {fontSize as fontSizeStyle} from 'style'

const font = ({fontSize, muted, bold, italic, center, right, left}) => {
  const out = {}

  if (fontSize || fontSize === 0) {
    out['fontSize'] = fontSizeStyle(fontSize)
  }
  if (muted) {
    out['color'] = 'rgba(0, 0, 0, 0.4)'
  }
  if (bold) {
    out['fontWeight'] = 'bold'
  }
  if (italic) {
    out['fontStyle'] = 'italic'
  }
  if (center) {
    out['textAlign'] = 'center'
  }
  if (right) {
    out['textAlign'] = 'right'
  }
  if (left) {
    out['textAlign'] = 'left'
  }

  return out
}

export default font
