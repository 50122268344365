import React from 'react'
import {Div} from '../elements'
import {getIsSection} from '../utils'

const styles = {
  base: {},
  title: {
    fontSize: 16,
  },
  description: {
    color: '#666',
    fontSize: 12,
  },
}

function Wish({wish: {title, description, url}}: Props) {
  const isSection = getIsSection(title)
  const formattedTitle = isSection ? title.slice(0, -1) : title

  return (
    <Div flex={true} pl={!isSection && 5} mt={isSection ? 5 : 1} bold={isSection}>
      <Div mr={2}>{isSection ? '' : '• '}</Div>
      <Div>
        <div style={styles.title}>
          {url ? (
            <a href={url} className="link">
              {formattedTitle}
            </a>
          ) : (
            formattedTitle
          )}
        </div>
        {description && <div style={styles.description}>{description}</div>}
      </Div>
    </Div>
  )
}

interface Props {
  wish: {
    title: string
    description: string
    url: string
  }
}

export default Wish
