import React from 'react'

import {prefix, border, space} from 'style'

import {Div, Button} from 'elements'

const Action = props => {
  return (
    <Button mh={2} {...props}>
      {props.title}
    </Button>
  )
}

const keyFromAction = action => {
  return typeof action.text === 'object' ? action.text.id : action.text || action.id || action.title
}

const ModalFooter = props => {
  const hasActions = props.actions || props.leftActions || props.rightActions
  if (!hasActions && !props.children) return null

  const styles = {
    base: {
      borderTopStyle: border.style,
      borderTopWidth: border.width,
      borderTopColor: border.color,
      //   borderBottomLeftRadius: border.radius,
      //   borderBottomRightRadius: border.radius,
      //   backgroundColor: 'rgba(0,0,0,0.03)',
      height: space(16) - 1,
    },
  }

  if (props.children) {
    return (
      <Div ph={4} flex justifyContent="flex-end" alignItems="center" style={prefix([styles.base, props.style])}>
        {props.children}
      </Div>
    )
  }

  return (
    <div>
      <Div
        ph={hasActions ? 2 : 4}
        flex
        justifyContent="space-between"
        alignItems="center"
        style={prefix([styles.base, props.style])}
      >
        <div>
          {(props.leftActions || []).map(action => {
            return <Action key={keyFromAction(action)} disabled={props.disabled} {...action} />
          })}
        </div>
        <div>
          {(props.rightActions || props.actions || []).map(action => {
            return <Action key={keyFromAction(action)} disabled={props.disabled} {...action} />
          })}
          {props.children}
        </div>
      </Div>
    </div>
  )
}

export default ModalFooter
