// Utility for extracting border props from components
import {border as borderStyle} from 'style'

const map = (styleKey, on) => {
  if (on === false) return {[`${styleKey}Width`]: 0}
  if (!on) return null
  return {
    [`${styleKey}Width`]: borderStyle.width,
    [`${styleKey}Style`]: borderStyle.style,
    [`${styleKey}Color`]: borderStyle.color,
  }
}

const border = ({b, bh, bv, bt, br, bb, bl}) => ({
  ...map('border', b),
  ...map('borderTop', bt),
  ...map('borderBottom', bb),
  ...map('borderTop', bv),
  ...map('borderBottom', bv),
  ...map('borderLeft', bl),
  ...map('borderRight', br),
  ...map('borderLeft', bh),
  ...map('borderRight', bh),
})

export default border
