import uid from 'uid'

import ACTION_TYPES from 'actionTypes'

import wish from './wish'

const arrayToObjectById = arr => Object.assign({}, ...arr.map(item => ({[item['id']]: item})))

const wishes = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.WISHES__SET_ACTIVE: {
      const {id} = action.payload

      const newState = arrayToObjectById(
        Object.keys(state)
          .map(key => state[key])
          .map(wish => {
            return {
              ...wish,
              isActive: false,
            }
          }),
      )

      return {
        ...newState,
        [id]: {
          ...newState[id],
          isActive: true,
        },
      }
    }

    case ACTION_TYPES.WISHES__CLEAR_ACTIVE: {
      const newState = arrayToObjectById(
        Object.keys(state)
          .map(key => state[key])
          .map(wish => {
            return {
              ...wish,
              isActive: false,
            }
          }),
      )

      return {
        ...newState,
      }
    }

    case ACTION_TYPES.WISHES__ADD_SECTION: {
      const id = uid()
      let newState = {...state}
      const position = Object.keys(newState).length

      return {
        ...newState,
        [id]: {
          id,
          title: 'Ny sektion:',
          isActive: true,
          position: position + 1,
        },
      }
    }

    case ACTION_TYPES.WISHES__ADD_WISH: {
      const id = uid()

      let newState = {...state}
      let position

      if (action.payload.position) {
        position = action.payload.position

        newState = arrayToObjectById(
          Object.keys(state)
            .map(key => state[key])
            .map(wish => {
              return {
                ...wish,
                position: wish.position > position ? wish.position + 1 : wish.position,
                isActive: false,
              }
            }),
        )
      } else {
        position = Object.keys(newState).length
      }

      return {
        ...newState,
        [id]: {
          id,
          title: '',
          isActive: true,
          position: position + 1,
        },
      }
    }

    case ACTION_TYPES.WISHES__REMOVE_WISH: {
      const {id, position} = action.payload

      return arrayToObjectById(
        Object.keys(state)
          .map(key => state[key])
          .filter(wish => wish.id !== id)
          .map(wish => {
            return {
              ...wish,
              position: wish.position > position ? wish.position - 1 : wish.position,
              isActive: wish.position === position - 1,
            }
          }),
      )
    }

    case ACTION_TYPES.WISHES__MOVE_CURSOR_UP: {
      const {id, position} = action.payload
      const wishAbove = Object.keys(state)
        .map(key => state[key])
        .find(wish => wish.position === position - 1)

      return {
        ...state,
        [id]: {
          ...state[id],
          isActive: false,
        },
        [wishAbove.id]: {
          ...state[wishAbove.id],
          isActive: true,
        },
      }
    }

    case ACTION_TYPES.WISHES__MOVE_CURSOR_DOWN: {
      const {id, position} = action.payload
      const wishBelow = Object.keys(state)
        .map(key => state[key])
        .find(wish => wish.position === position + 1)
      return {
        ...state,
        [id]: {
          ...state[id],
          isActive: false,
        },
        [wishBelow.id]: {
          ...state[wishBelow.id],
          isActive: true,
        },
      }
    }

    case ACTION_TYPES.WISHES__REORDER: {
      const {start, end} = action.payload

      const sortedArray = Object.keys(state)
        .map(key => state[key])
        .map(wish => {
          const pos = wish.position
          let newPos = pos
          if (pos === start) {
            newPos = end
          } else if (start < end && pos <= end && pos > start) {
            newPos = pos - 1
          } else if (start > end && pos >= end && pos < start) {
            newPos = pos + 1
          }

          return {
            ...wish,
            position: newPos,
          }
        })

      return arrayToObjectById(sortedArray)
    }

    default: {
      if (action.payload.id) {
        const {id} = action.payload
        return {
          ...state,
          [id]: wish(state[id], action),
        }
      }
    }
  }
}

export default wishes
