import {navigate} from '@reach/router'
import ACTIONS from 'actions'
import ACTION_TYPES from 'actionTypes'
import {all, delay, call, fork, put, takeEvery} from 'redux-saga/effects'
import {db, firebase, rsf} from './firebase'

function* fetchWishLists() {
  yield put(ACTIONS.WISH_LISTS__WILL_FETCH())
  const snapshot = yield call(rsf.firestore.getCollection, 'wishLists')
  let wishLists
  snapshot.forEach(wishList => {
    wishLists = {
      ...wishLists,
      [wishList.id]: wishList.data(),
    }
  })
  // Add ids to elements
  Object.keys(wishLists).forEach(wishListKey => {
    wishLists[wishListKey].id = wishListKey

    !!wishLists[wishListKey].wishes &&
      Object.keys(wishLists[wishListKey].wishes).forEach(wishKey => {
        wishLists[wishListKey].wishes[wishKey].id = wishKey
        // wishLists[wishListKey].wishes[wishKey].position = index + 1
      })
  })
  yield put(ACTIONS.WISH_LISTS__DID_FETCH({wishLists}))
}

function* createWishList({payload}) {
  yield put(ACTIONS.WISH_LIST__WILL_CREATE())
  const firstName = payload.user.displayName.split(' ')[0]

  const createdAt = new Date().toISOString()
  const newWishList = {
    title: `${firstName}s ønskeseddel`,
    owner: payload.user.id,
    createdAt,
    updatedAt: createdAt,
  }
  const wishList = yield call(rsf.firestore.addDocument, `wishLists`, newWishList)
  const id = wishList.id
  // yield call(rsf.firestore.addDocument, `people/${payload.userId}`, {wishLists: id})
  yield put(
    ACTIONS.WISH_LIST__DID_CREATE({
      wishList: {
        [id]: {
          id,
          ...newWishList,
        },
      },
    }),
  )
  navigate(`/lists/${id}/edit`)
}

function* saveWishList({payload}) {
  yield put(ACTIONS.WISH_LIST__WILL_SAVE())
  const {id, owner, title} = payload.wishList

  const updatedAt = new Date().toISOString()
  const wishListSaveData = {
    owner,
    title,
    updatedAt,
  }

  let wishesSaveData
  if (payload.wishList.wishes) {
    wishesSaveData = Object.keys(payload.wishList.wishes)
      .map(key => payload.wishList.wishes[key])
      .reduce((acc, {id, title = '', description = '', url = '', position}) => {
        return {
          ...acc,
          [id]: {
            title,
            description,
            url,
            position,
          },
        }
      }, {})
  }

  if (wishesSaveData) {
    wishListSaveData['wishes'] = wishesSaveData
  }

  yield call(rsf.firestore.setDocument, `wishLists/${id}`, wishListSaveData)
  yield delay(500)
  yield put(ACTIONS.WISH_LIST__DID_SAVE({...payload, updatedAt}))
  navigate(`/lists/${payload.wishListId}`)
}

function* updateWishListTitle({payload}) {
  yield call(rsf.firestore.updateDocument, `wishLists/${payload.wishListId}`, 'title', payload.title)
}

function* deleteWishList({payload}) {
  yield put(ACTIONS.WISH_LIST__WILL_DELETE())
  yield call(rsf.firestore.deleteDocument, `wishLists/${payload.id}`)
  yield put(ACTIONS.WISH_LIST__DID_DELETE(payload))
  navigate('/lists/')
}

function* addWishListEditor({payload}) {
  yield put(ACTIONS.WISH_LIST__WILL_ADD_EDITOR())
  const wishListRef = db.collection('wishLists').doc(payload.wishListId)
  yield wishListRef.update({
    editors: firebase.firestore.FieldValue.arrayUnion(payload.editorId),
  })
  yield put(ACTIONS.WISH_LIST__DID_ADD_EDITOR(payload))
}

function* deleteWishListEditor({payload}) {
  yield put(ACTIONS.WISH_LIST__WILL_DELETE_EDITOR())
  const wishListRef = db.collection('wishLists').doc(payload.wishListId)
  yield wishListRef.update({
    editors: firebase.firestore.FieldValue.arrayRemove(payload.editorId),
  })
  yield put(ACTIONS.WISH_LIST__DID_DELETE_EDITOR(payload))
}

export default function* wishListsSaga() {
  yield all([
    fork(fetchWishLists),
    takeEvery(ACTION_TYPES.WISH_LIST__CREATE, createWishList),
    takeEvery(ACTION_TYPES.WISH_LIST__SAVE, saveWishList),
    takeEvery(ACTION_TYPES.WISH_LIST__UPDATE_TITLE, updateWishListTitle),
    takeEvery(ACTION_TYPES.WISH_LIST__DELETE, deleteWishList),
    takeEvery(ACTION_TYPES.WISH_LIST__ADD_EDITOR, addWishListEditor),
    takeEvery(ACTION_TYPES.WISH_LIST__DELETE_EDITOR, deleteWishListEditor),
  ])
}
