import ACTION_TYPES from 'actionTypes'
import wishList from './wishList'

const initialState = {
  isLoading: false,
  items: {},
}

const wishLists = (state = initialState, action) => {
  if (action.payload && action.payload.wishListId) {
    const {wishListId} = action.payload
    return {
      ...state,
      items: {
        ...state.items,
        [wishListId]: wishList(state.items[wishListId], action),
      },
    }
  }

  switch (action.type) {
    case ACTION_TYPES.WISH_LISTS__WILL_FETCH:
      return {
        ...state,
        isLoading: true,
      }

    case ACTION_TYPES.WISH_LISTS__DID_FETCH:
      return {
        ...state,
        isLoading: false,
        items: {
          ...state.items,
          ...action.payload.wishLists,
        },
      }

    case ACTION_TYPES.WISH_LIST__DID_CREATE: {
      const {wishList} = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          ...wishList,
        },
      }
    }

    case ACTION_TYPES.WISH_LIST__DID_DELETE: {
      const newState = {...state}
      delete newState.items[action.payload.id]
      return newState
    }

    default: {
      return state
    }
  }
}

export default wishLists
