import {RouteComponentProps} from '@reach/router'
import {HasSignedIn} from 'components'
import {H1, Link} from 'elements'
import React from 'react'
import {useSelector} from 'react-redux'
import {People as IPeople} from '../interfaces'

interface State {
  people: IPeople
}

const People: React.FC<RouteComponentProps> = () => {
  const people = useSelector((state: State) => {
    const items = state.people.items
    if (!items) {
      return null
    }
    return Object.keys(items)
      .map(key => items[key])
      .sort((a, b) => {
        if (!a.displayName || !b.displayName) {
          return -1
        }
        return a.displayName.toUpperCase() < b.displayName.toUpperCase() ? -1 : 1
      })
  })

  const styles = {
    list: {
      listStyleType: 'none',
      paddingLeft: 0,
    },
    link: {
      display: 'block',
    },
  }

  if (!people) {
    return null
  }

  return (
    <HasSignedIn>
      <H1 m={0}>Personer</H1>
      <ul style={styles.list}>
        {people.map(({id, displayName}) => (
          <li key={`person-${id}`}>
            <Link to={`/people/${id}`} pv={1} className="link-complex" style={styles.link}>
              <span className="link-complex-target">{displayName}</span>
            </Link>
          </li>
        ))}
      </ul>
    </HasSignedIn>
  )
}

export default People
