import ACTION_TYPES from 'actionTypes'
import wishes from './wishes'

const wishList = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.WISH_LIST__UPDATE_TITLE: {
      const {title} = action.payload
      return {
        ...state,
        title,
      }
    }

    case ACTION_TYPES.WISH_LIST__ADD_EDITOR:
      return state

    case ACTION_TYPES.WISH_LIST__DID_ADD_EDITOR: {
      const {editorId} = action.payload
      const editors = state.editors ? [...state.editors, editorId] : editorId
      return {
        ...state,
        editors,
      }
    }

    case ACTION_TYPES.WISH_LIST__DELETE_EDITOR:
      return state

    case ACTION_TYPES.WISH_LIST__DID_DELETE_EDITOR:
      return {
        ...state,
        editors: state.editors.filter(item => item !== action.payload.editorId),
      }

    case ACTION_TYPES.WISH_LIST__CLOSE_SHARE_MODAL:
      return {
        ...state,
        isPermissionsModalOpen: false,
      }

    case ACTION_TYPES.WISH_LIST__SAVE:
      return {
        ...state,
        isSaving: true,
      }

    case ACTION_TYPES.WISH_LIST__DID_SAVE:
      return {
        ...state,
        updatedAt: action.payload.updatedAt,
        isSaving: false,
      }

    default: {
      return {
        ...state,
        wishes: wishes(state.wishes, action),
      }
    }
  }
}

export default wishList
