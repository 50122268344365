import React from 'react'
import {space, withStyle} from '../style'

const styles = {
  fontWeight: 700,
  display: 'block',
  marginBottom: space(2),
}

const H2 = (props: Props) => <label style={{...styles, ...props.style}}>{props.children}</label>

export default withStyle(H2)

interface Props {
  children: React.ReactNode
  style: object
}
