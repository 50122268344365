import ACTIONS from 'actions'
import ACTION_TYPES from 'actionTypes'
import {all, call, fork, put, take, takeEvery} from 'redux-saga/effects'
import {firebase, rsf} from './firebase'

const authProvider = new firebase.auth.FacebookAuthProvider()

function* signIn() {
  try {
    const data = yield call(rsf.auth.signInWithPopup, authProvider)
    yield put(ACTIONS.AUTH__SIGN_IN_SUCCESS(data))
  } catch (error) {
    yield put(ACTIONS.AUTH__SIGN_IN_FAILURE(error))
  }
}

function* signOut() {
  try {
    yield call(rsf.auth.signOut)
    yield put(ACTIONS.AUTH__SIGN_OUT_SUCCESS())
  } catch (error) {
    yield put(ACTIONS.AUTH__SIGN_OUT_FAILURE(error))
  }
}

function* authStatusWatcher() {
  // events on this channel fire when the user logs in or logs out
  const channel = yield call(rsf.auth.channel)

  while (true) {
    const {user} = yield take(channel)

    if (user) {
      const {uid, displayName, email, photoURL} = user
      const snapshot = yield call(rsf.firestore.getDocument, `users/${uid}`)
      if (snapshot.data()) {
        yield put(ACTIONS.AUTH__SIGN_IN_SUCCESS({user: {id: uid, ...snapshot.data()}}))
      } else {
        const newUser = {displayName, email, photoURL}
        yield call(rsf.firestore.setDocument, `users/${uid}`, newUser)
        yield put(ACTIONS.AUTH__SIGN_IN_SUCCESS({user: {id: uid, ...newUser}}))
      }
    } else {
      yield put(ACTIONS.AUTH__SIGN_OUT_SUCCESS())
    }
  }
}

export default function* authSaga() {
  yield fork(authStatusWatcher)
  yield all([
    takeEvery(ACTION_TYPES.AUTH__SIGN_IN_REQUEST, signIn),
    takeEvery(ACTION_TYPES.AUTH__SIGN_OUT_REQUEST, signOut),
  ])
}
