import React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd'
import {useDispatch} from 'react-redux'

import ACTION_TYPES from 'actionTypes'
import {EditWish} from 'components'
import {color, space} from 'style'
import {orderByPosition} from 'utils'
import {Wishes} from '../interfaces'

const getItemStyle = (isDragging: boolean, draggableStyle: any): {} => ({
  userSelect: 'none',
  padding: space(),
  background: isDragging && 'rgba(0, 0, 0, .6)',
  color: isDragging && color.white,
  borderRadius: space(),
  ...draggableStyle,
})

const getListStyle = (isDraggingOver: boolean): {} => ({
  backgroundColor: isDraggingOver ? 'rgba(0,0,0,.1)' : 'transparent',
})

interface Props {
  wishListId: string
  wishes: Wishes
  hasSections: boolean
}

const EditWishes: React.FC<Props> = ({wishListId, wishes, hasSections}) => {
  const dispatch = useDispatch()

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    if (!destination) {
      return
    }
    dispatch({
      type: ACTION_TYPES.WISHES__REORDER,
      payload: {
        wishListId,
        start: source.index + 1,
        end: destination.index + 1,
      },
    })
  }

  const orderedWishes = orderByPosition(wishes)

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
            {orderedWishes.map((wish, index) => (
              <Draggable key={wish.id} draggableId={wish.id} index={index}>
                {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                  <div>
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      style={getItemStyle(snapshotDraggable.isDragging, providedDraggable.draggableProps.style)}
                    >
                      <EditWish
                        key={index}
                        wishListId={wishListId}
                        wish={wish}
                        hasSections={hasSections}
                        isFirst={index === 0}
                        isLast={index === orderedWishes.length - 1}
                      />
                    </div>
                    {providedDraggable.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default EditWishes
