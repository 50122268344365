import React, {ChangeEvent} from 'react'
import {ThemeContext} from 'theme'
import {color, font, space, withStyle} from '../style'

interface Props {
  focus: boolean
  focusOnUpdate: boolean
  selectSection: boolean
  size: string
  id: string
  value: string
  autoComplete: string
  placeholder: string
  style: object
  onChange(): ChangeEvent
  onKeyPress(): KeyboardEvent
  onKeyUp(): KeyboardEvent
  onKeyDown(): KeyboardEvent
  onClick(): MouseEvent
  onFocus(): FocusEvent
}

const Input: React.FC<Props> = props => {
  const theme = React.useContext(ThemeContext)
  const inputEl = React.useRef<HTMLInputElement>(null)

  const styles: any = {
    base: {
      background: theme.background,
      color: theme.text,
      borderColor: theme.border,
      borderStyle: 'solid',
      borderWidth: 1,
      fontFamily: font.family,
      fontSize: 'inherit',
      outline: 'none',
      WebkitAppearance: 'none',
      width: '100%',
    },

    sm: {
      fontSize: 12,
      height: space(6),
      paddingLeft: space(2),
      paddingRight: space(2),
    },
    md: {
      fontSize: 16,
      height: space(9),
      paddingLeft: space(3),
      paddingRight: space(3),
    },
    lg: {
      fontSize: 24,
      height: space(12),
      paddingLeft: space(5),
      paddingRight: space(5),
    },
  }

  React.useEffect(() => {
    if (inputEl && inputEl.current) {
      const node = inputEl.current
      if (props.focus || props.focusOnUpdate) {
        node.focus()
      }
      if (props.selectSection) {
        node.focus()
        node.setSelectionRange(0, node.value.length - 1)
      }
      if (props.focusOnUpdate) {
        node.focus()
      }
    }
  }, [props.focusOnUpdate, props.focus, props.selectSection])

  return (
    <input
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      onKeyUp={props.onKeyUp}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      onFocus={props.onFocus}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      style={{...styles.base, ...styles[props.size || 'md'], ...props.style}}
      ref={inputEl}
    />
  )
}
export default withStyle(Input)
