import {combineReducers} from 'redux'

import people from './people'
import ui from './ui'
import user from './user'
import wishLists from './wishLists'

const reducer = combineReducers({
  ui,
  user,
  wishLists,
  people,
})

export default reducer
