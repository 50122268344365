import Prefixer from 'inline-style-prefixer'

const flatten = array => {
  return array.reduce((out, item) => {
    if (item === false) return out
    return {...out, ...item}
  }, {})
}

const prefix = objectOrArray => {
  const styleObject = objectOrArray && Array.isArray(objectOrArray) ? flatten(objectOrArray) : objectOrArray
  const prefixer = new Prefixer()
  return prefixer.prefix(styleObject)
}

export {flatten, prefix}
