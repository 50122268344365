import {Router} from '@reach/router'
import dayjs from 'dayjs'
import 'dayjs/locale/da'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import {useSelector} from 'react-redux'
import {ThemeContext, themes} from 'theme'
import {Div} from '../elements'
import {EditWishList, FrontPage, Navbar, NotFound, People, Person, SignIn, WishList, WishLists} from './'

import {WishLists as IWishList} from '../interfaces'

dayjs.locale('da')
dayjs.extend(relativeTime)

interface State {
  wishLists: IWishList
  people: {
    isLoading: boolean
  }
  ui: {
    isDarkMode: boolean
  }
}

const appAreas = `
  'navbar'
  'main'
  'foot'
`

const App: React.FC = () => {
  const isDarkMode = useSelector((state: State) => {
    return state.ui.isDarkMode
  })

  return (
    <ThemeContext.Provider value={isDarkMode ? themes.dark : themes.light}>
      <Layout />
    </ThemeContext.Provider>
  )
}

const Layout: React.FC = () => {
  const theme = React.useContext(ThemeContext)

  const isLoading = useSelector(({wishLists, people}: State) => {
    return wishLists.isLoading || people.isLoading
  })

  const styles = {
    base: {
      backgroundColor: theme.background,
      color: theme.text,
      minHeight: '100vh',
      overflow: 'hidden',
    },
    main: {
      overflow: 'auto',
    },
    foot: {
      borderTop: `1px solid ${theme.border}`,
    },
  }

  return (
    <Div
      grid={true}
      gridTemplateAreas={appAreas}
      gridTemplateColumns="100%"
      gridTemplateRows="48px 1fr 48px"
      fullHeight={true}
      style={styles.base}
    >
      <Navbar />
      <Div gridArea="main" pt={8} ph={3} style={styles.main}>
        {isLoading ? (
          <Div flex={true} justifyContent="center" alignItems="center" italic={true}>
            <Div>Henter...</Div>
          </Div>
        ) : (
          <>
            <Router>
              <FrontPage path="/" />
              <WishLists path="/lists" />
              <WishList path="/lists/:id" />
              <EditWishList path="/lists/:id/edit" />
              <People path="people" />
              <Person path="people/:id" />
              <SignIn path="sign-in" />
              <NotFound default={true} />
            </Router>
          </>
        )}
      </Div>
      <Div gridArea="foot" ph={3} flex={true} alignItems="center" style={styles.foot}>
        ♥ YouWish {new Date().getFullYear()}{' '}
      </Div>
    </Div>
  )
}

export default App
