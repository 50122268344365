// Base Unit ==================
const baseUnit = 4

// Font =======================
const font = {
  family: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  lineHeight: 1.5,
  weight: {
    // thin: 100,
    // ultraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    // ultraBold: 800,
    // black: 900,
  },
  familyMonospace: 'Courier',
}

// Font size ===================
const fontSizeByNumber = {
  '6': 72, // huger
  '5': 48, // huger
  '4': 36, // huge
  '3': 32, // lagest
  '2': 24, // larger
  '1': 21, // large
  '0': 16, // regular
  '-1': 14, // small
  '-2': 11, // smaller
  '-3': 10, // smallest
  '-4': 8, // tiny
}
const fontSize = number => {
  return fontSizeByNumber[number]
}

// Space ======================
const space = size => {
  if (size === undefined) {
    return baseUnit
  }
  return baseUnit * size
}

// Color ======================
const color = {
  primary: '#ff1d25',
  primaryDark: '#d50007',

  text: '#333',

  white: '#fff',
  black: '#000',

  grayFA: '#fafafa',
  grayE: '#eee',
  grayD: '#ddd',
  grayC: '#ccc',
  grayB: '#bbb',
  grayA: '#aaa',
  gray9: '#999',
  gray8: '#888',
  gray7: '#777',
  gray6: '#666',
  gray5: '#555',
  gray4: '#444',
  gray3: '#333',
  gray2: '#222',
  gray1: '#111',

  border: 'rgba(0, 0, 0, .125)',
}

// Border ======================
const border = {
  width: 1,
  style: 'solid',
  radius: 4,
  color: color.border,
}

// zIndex ======================
const zIndex = {
  mobileMenu: 1000,
  overlay: 900,
  navbar: 800,
}

const maxWidth = 720

export {baseUnit, font, fontSize, space, color, border, zIndex, maxWidth}
