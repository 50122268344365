import border from './border'
import color from './color'
import flex from './flex'
import font from './font'
import grid from './grid'
import padding from './padding'
import margin from './margin'
import size from './size'
import radii from './radii'

const stylesFromProps = props => ({
  ...border(props),
  ...color(props),
  ...flex(props),
  ...font(props),
  ...grid(props),
  ...padding(props),
  ...margin(props),
  ...size(props),
  ...radii(props),
})

export default stylesFromProps
