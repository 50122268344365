import React from 'react'

import {prefix, space} from 'style'

import {Div} from 'elements'

const ModalBody = props => {
  const styles = {
    base: {
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      minHeight: 0,
      paddingTop: space(4),
    },
  }

  return (
    <Div p={4} style={prefix([styles.base, props.style])}>
      {props.children}
    </Div>
  )
}

export default ModalBody
