import React from 'react'
import {font, space, withStyle} from '../style'

const styles: any = {
  base: {
    fontFamily: font.family,
    fontSize: 'inherit',
    borderWidth: 1,
    borderStyle: 'solid',
    width: '100%',
    minHeight: 72,
    borderColor: '#ccc',
    WebkitAppearance: 'none',
    background: '#fff',
    outline: 'none',
  },

  sm: {
    paddingTop: space(2),
    paddingRight: space(2),
    paddingBottom: space(2),
    paddingLeft: space(2),
    fontSize: 12,
  },
  md: {
    paddingTop: space(3),
    paddingRight: space(3),
    paddingBottom: space(3),
    paddingLeft: space(3),
    fontSize: 16,
  },
  lg: {
    paddingTop: space(5),
    paddingRight: space(5),
    paddingBottom: space(5),
    paddingLeft: space(5),
    fontSize: 24,
  },
}

interface Props {
  focus: boolean
  focusOnUpdate: boolean
  selectSection: boolean
  size: string
  id: string
  value: string
  autoComplete: string
  placeholder: string
  style: object
  children: React.ReactNode
  onChange(): void
  onKeyPress(): void
  onKeyUp(): void
  onKeyDown(): void
  onClick(): void
  onFocus(): void
}

const Textarea: React.FC<Props> = ({size = 'md', ...props}) => {
  const textareaEl = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(() => {
    if (textareaEl && textareaEl.current) {
      const node = textareaEl.current
      if (props.focus || props.focusOnUpdate) {
        node.focus()
      }
    }
  }, [props.focus, props.focusOnUpdate])

  return (
    <textarea
      id={props.id}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      onKeyUp={props.onKeyUp}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      onFocus={props.onFocus}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
      style={{...styles.base, ...styles[size], ...props.style}}
      ref={textareaEl}
    >
      {props.children}
    </textarea>
  )
}

export default withStyle(Textarea)
