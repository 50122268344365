import React from 'react'
import {useDispatch} from 'react-redux'

import ACTION_TYPES from 'actionTypes'
import {Input, Label, Modal, Textarea} from 'elements'
import {getIsSection} from 'utils'
import {Wish} from '../interfaces'

interface Props {
  wishListId: string
  wish: Wish
}

const EditWishUrlModal: React.FC<Props> = ({wishListId, wish}) => {
  const dispatch = useDispatch()
  const {id, title} = wish
  const isSection = getIsSection(title)

  const [description, setDescription] = React.useState(wish.description)
  const [url, setUrl] = React.useState(wish.url)

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }

  const cancel = () => {
    setDescription(wish.description)
    setUrl(wish.url)
    dispatch({
      type: ACTION_TYPES.WISH__CLOSE_MODAL,
      payload: {
        wishListId,
        id,
      },
    })
  }

  const save = () => {
    dispatch({
      type: ACTION_TYPES.WISH__UPDATE_DESCRIPTION,
      payload: {wishListId, id, description},
    })
    dispatch({
      type: ACTION_TYPES.WISH__UPDATE_URL,
      payload: {wishListId, id, url},
    })
    dispatch({
      type: ACTION_TYPES.WISH__CLOSE_MODAL,
      payload: {
        wishListId,
        id,
      },
    })
  }

  return (
    <Modal
      isOpen={wish.isEditUrlModalOpen}
      onDismiss={cancel}
      verticalPosition="center"
      title={`Ret ${isSection ? 'sektion' : 'ønske'}`}
      actions={[{title: 'Annullér', onClick: cancel}, {title: 'Gem', type: 'primary', onClick: save}]}
    >
      <Label>Beskrivelse</Label>
      <Textarea onChange={handleDescriptionChange}>{description}</Textarea>
      <Label>Link</Label>
      <Input value={url} type="url" onChange={handleUrlChange} />
    </Modal>
  )
}

export default EditWishUrlModal
