import React from 'react'

export const themes = {
  light: {
    text: '#000',
    background: '#fff',
    border: '#ddd',
  },
  dark: {
    text: '#fff',
    background: '#000',
    border: '#444',
  },
}

export const ThemeContext = React.createContext(
  themes.light, // default value
)
