const map = (styleKey, size) => {
  if (size == null) return
  const value = size === false ? 'none' : size * 4
  return {
    [styleKey]: value,
  }
}

const padding = ({p, ph, pv, pt, pr, pb, pl}) => ({
  ...map('paddingTop', p),
  ...map('paddingBottom', p),
  ...map('paddingLeft', p),
  ...map('paddingRight', p),

  ...map('paddingTop', pv),
  ...map('paddingBottom', pv),
  ...map('paddingLeft', ph),
  ...map('paddingRight', ph),

  ...map('paddingTop', pt),
  ...map('paddingBottom', pb),
  ...map('paddingLeft', pl),
  ...map('paddingRight', pr),
})

export default padding
