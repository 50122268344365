const flex = ({
  grid,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  justifyItems,
  placeItems,
  alignContent,
  gridArea,
  gridColumn,
  gridRow,
  gridAutoColumns,
  gridAutoRows,
  gridAutoFlow,
  justifySelf,
  alignSelf,
  placeSelf,
}) => {
  const style = {}

  // alignItems and justifyContent is part of flex util already
  if (grid) {
    style['display'] = 'grid'
  }
  if (gridTemplateColumns) {
    style['gridTemplateColumns'] = gridTemplateColumns
  }
  if (gridTemplateRows) {
    style['gridTemplateRows'] = gridTemplateRows
  }
  if (gridTemplateAreas) {
    style['gridTemplateAreas'] = gridTemplateAreas
  }
  if (justifyItems) {
    style['justifyItems'] = justifyItems
  }
  if (placeItems) {
    style['placeItems'] = placeItems
  }
  if (alignContent) {
    style['alignContent'] = alignContent
  }
  if (gridColumn) {
    style['gridColumn'] = gridColumn
  }
  if (gridRow) {
    style['gridRow'] = gridRow
  }
  if (gridAutoColumns) {
    style['gridAutoColumns'] = gridAutoColumns
  }
  if (gridAutoRows) {
    style['gridAutoRows'] = gridAutoRows
  }
  if (gridAutoFlow) {
    style['gridAutoFlow'] = gridAutoFlow
  }
  if (gridArea) {
    style['gridArea'] = gridArea
  }
  if (justifySelf) {
    style['justifySelf'] = justifySelf
  }
  if (alignSelf) {
    style['alignSelf'] = alignSelf
  }
  if (placeSelf) {
    style['placeSelf'] = placeSelf
  }
  return style
}

export default flex
