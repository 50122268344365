import ACTION_TYPES from 'actionTypes'

const initialState = {
  isLoading: false,
  items: {},
}

interface Action {
  type: string
  payload: any
}

const people = (state = initialState, action: Action) => {
  switch (action.type) {
    case ACTION_TYPES.PEOPLE__WILL_FETCH:
      return {
        ...state,
        isLoading: true,
      }

    case ACTION_TYPES.PEOPLE__DID_FETCH:
      return {
        ...state,
        isLoading: false,
        items: action.payload.people,
      }

    default: {
      return state
    }
  }
}

export default people
