import {Button, Overlay} from 'elements'
import {withButton} from 'hocs'
import React, {Component} from 'react'
import {color, prefix, withStyle} from 'style'
import {globalComponentStack} from 'utils'
import DropdownMenu from './Menu'

class DropdownButton extends Component {
  buttonRef = React.createRef()

  state = {
    isOpen: false,
  }

  close = () => {
    const close = this.props.onClose !== undefined ? this.props.onClose : () => this.setState({isOpen: false})
    close()
  }

  isOpen = () => {
    return this.isOpenByStateAndProps(this.state, this.props)
  }

  isOpenByStateAndProps = (state, props) => {
    return props.isOpen !== undefined ? props.isOpen : state.isOpen
  }

  componentDidMount() {
    if (this.isOpen()) {
      globalComponentStack.add(this)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isOpenByStateAndProps(prevState, prevProps) && !this.isOpenByStateAndProps(this.state, this.props)) {
      globalComponentStack.remove(this)
    }
    if (!this.isOpenByStateAndProps(prevState, prevProps) && this.isOpenByStateAndProps(this.state, this.props)) {
      globalComponentStack.add(this)
    }
  }

  componentWillUnmount() {
    if (this.isOpen()) {
      globalComponentStack.remove(this)
    }
  }

  onGlobalComponentStackDidReceiveEscapeForThisComponent() {
    globalComponentStack.remove(this)
    this.close()
  }

  render() {
    const verticalMenuPlacement = this.props.verticalMenuPlacement || 'bottom'
    const horizontalMenuPlacement = this.props.horizontalMenuPlacement || 'left'

    const styles = {
      outer: {
        display: this.props.block ? 'block' : 'inline-block',
      },
      arrow: {
        color: color.grayB,
        fontSize: 11,
        paddingLeft: 6,
      },
    }

    const isOpen = this.isOpen()
    const onOpen = this.props.onOpen !== undefined ? this.props.onOpen : () => this.setState({isOpen: true})
    const onClose = this.close
    const onToggle = isOpen ? onClose : onOpen
    const onClick = onToggle
    const active = isOpen
    const horizontalTextAlignment = this.props.horizontalTextAlignment || 'left'

    const buttonOptions = {
      outline: this.props.outline,
      circle: this.props.circle,
      block: this.props.block,
      type: this.props.type,
      size: this.props.size,
      muted: this.props.mutedButton && !active,
      active,
      onClick,
      horizontalTextAlignment,
      disabled: this.props.disabled,
    }

    return (
      <div style={prefix([this.props.style])}>
        <div style={prefix([styles.outer])}>
          {isOpen && <Overlay onDismiss={onClose} isBackgroundTransparent={true} />}
          <div ref={this.buttonRef}>
            {this.props.renderButton ? (
              this.props.renderButton({isOpen, onToggle})
            ) : (
              <Button {...buttonOptions}>
                {this.props.text} <span style={styles.arrow}>{isOpen ? ' ▲' : ' ▼'}</span>
              </Button>
            )}
          </div>
          {isOpen && (
            <DropdownMenu
              type={this.props.type}
              onClose={onClose}
              closeOnSelect={this.props.closeOnSelect}
              horizontalMenuPlacement={horizontalMenuPlacement}
              verticalMenuPlacement={verticalMenuPlacement}
              buttonRef={this.buttonRef}
              renderNonClickableHeader={this.props.renderNonClickableHeader}
              renderMenu={this.props.renderMenu}
            >
              {this.props.children}
            </DropdownMenu>
          )}
        </div>
      </div>
    )
  }
}

DropdownButton.defaultProps = {
  type: 'secondary',
  closeOnSelect: true,
}

export default withStyle(withButton(DropdownButton))
