import React from 'react'
import {useSelector} from 'react-redux'
import {Person} from '../interfaces'

interface Props {
  children?: any
}

interface State {
  user: Person
}

const HasSignedIn: React.FC<Props> = ({children}) => {
  const user = useSelector((state: State) => {
    return state.user
  })

  return user ? children : null
}

export default HasSignedIn
