import ACTION_TYPES from 'actionTypes'

const wish = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.WISH__UPDATE_TITLE: {
      const {title} = action.payload
      return {
        ...state,
        title,
      }
    }

    case ACTION_TYPES.WISH__OPEN_MODAL: {
      return {
        ...state,
        isEditUrlModalOpen: true,
      }
    }

    case ACTION_TYPES.WISH__CLOSE_MODAL: {
      return {
        ...state,
        isEditUrlModalOpen: false,
      }
    }

    case ACTION_TYPES.WISH__UPDATE_DESCRIPTION: {
      const {description} = action.payload
      return {
        ...state,
        description,
      }
    }

    case ACTION_TYPES.WISH__UPDATE_URL: {
      const {url} = action.payload
      return {
        ...state,
        url,
      }
    }

    default: {
      return state
    }
  }
}

export default wish
