import {Redirect, RouteComponentProps} from '@reach/router'
import {H1, H2, Link} from 'elements'
import React from 'react'
import {useSelector} from 'react-redux'
import {People, WishLists} from '../interfaces'

interface State {
  wishLists: WishLists
  people: People
}

const Person: React.FC<RouteComponentProps<{id: string}>> = props => {
  const person = useSelector((state: State) => {
    if (!props.id) {
      return null
    }
    return state.people.items[props.id]
  })

  const wishLists = useSelector((state: State) => {
    return Object.keys(state.wishLists.items).map(key => state.wishLists.items[key])
  })

  const styles = {
    list: {
      listStyleType: 'none',
      paddingLeft: 0,
    },
    link: {
      display: 'block',
    },
  }

  if (!person) {
    return <Redirect noThrow={true} to="people" />
  }

  const personLists = wishLists.filter(wishList => {
    const isOwner = wishList.owner === person.id
    const isEditor = wishList.editors && wishList.editors.includes(person.id)
    return isOwner || isEditor
  })

  return (
    <>
      <H1 mt={0}>{person.displayName}</H1>
      {!!personLists && personLists.length > 0 && (
        <>
          <H2 m={0}>Ønskesedler</H2>
          <ul style={styles.list}>
            {personLists.map(({title, id}) => (
              <li key={id}>
                <Link to={`/lists/${id}`} pv={1} className="link-complex" style={styles.link}>
                  <span className="link-complex-target">{title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default Person
