import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {globalComponentStack, noScrollSemaphore} from 'utils'
import {prefix, zIndex} from 'style'

const overlayRoot = () => document.getElementById('overlay-root')

class ModalBase extends Component {
  el = document.createElement('div')
  overlayRef = React.createRef()

  static defaultProps = {
    onDismiss: () => {},
  }

  handleOverlayClick = e => {
    if (this.shouldDismissOnClickOutside() && this.overlayRef.current === e.target) this.props.onDismiss()
  }

  shouldDismissOnEscape = () => this.props.dismissOnEscape !== false
  shouldDismissOnClickOutside = () => this.props.dismissOnClickOutside !== false

  onGlobalComponentStackDidReceiveEscapeForThisComponent() {
    if (this.shouldDismissOnEscape()) {
      globalComponentStack.remove(this)
      this.props.onDismiss()
    }
  }

  onGlobalComponentStackDidAddThisComponent() {
    noScrollSemaphore.increase()
  }

  onGlobalComponentStackDidRemoveThisComponent() {
    noScrollSemaphore.decrease()
  }

  componentDidMount() {
    overlayRoot().appendChild(this.el)
    if (this.props.isOpen) {
      globalComponentStack.add(this)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen && !nextProps.isOpen) {
      globalComponentStack.remove(this)
    }
    if (!this.props.isOpen && nextProps.isOpen) {
      globalComponentStack.add(this)
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      globalComponentStack.remove(this)
    }
    overlayRoot().removeChild(this.el)
  }

  render() {
    const {isOpen, children} = this.props

    if (!isOpen) return null

    const styles = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: zIndex.overlay,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      modal: {
        zIndex: zIndex.overlay,
      },
    }
    const component = (
      <div ref={this.overlayRef} onClick={this.handleOverlayClick} style={prefix([styles.overlay])}>
        <div style={prefix(styles.modal)}>{children}</div>
      </div>
    )

    return ReactDOM.createPortal(component, this.el)
  }
}

export default ModalBase
