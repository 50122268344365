import {color as colorStyle} from 'style'

const color = ({color, bg, borderColor}) => {
  const out = {}
  if (color) {
    out['color'] = colorStyle[color]
  }
  if (bg) {
    out['backgroundColor'] = colorStyle[bg]
  }
  if (borderColor) {
    out['borderColor'] = colorStyle[borderColor]
  }
  return out
}

export default color
