const map = (styleKey, size) => {
  if (size == null) return
  const value = size === false ? 'none' : size * 4
  return {
    [styleKey]: value,
  }
}

const margin = ({m, mh, mv, mt, mr, mb, ml}) => ({
  ...map('marginTop', m),
  ...map('marginBottom', m),
  ...map('marginLeft', m),
  ...map('marginRight', m),

  ...map('marginTop', mv),
  ...map('marginBottom', mv),
  ...map('marginLeft', mh),
  ...map('marginRight', mh),

  ...map('marginTop', mt),
  ...map('marginBottom', mb),
  ...map('marginLeft', ml),
  ...map('marginRight', mr),
})

export default margin
