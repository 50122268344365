import ACTION_TYPES from 'actionTypes'
import {HasSignedIn} from 'components'
import {Button, Div, DropdownButton, DropdownItem, Link} from 'elements'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {withStyle} from 'style'
import {ThemeContext} from 'theme'
import {Person, UI} from '../interfaces'

interface State {
  user: Person
  ui: UI
}

const Navbar: React.FC = props => {
  const theme = React.useContext(ThemeContext)

  const styles = {
    base: {
      borderBottom: `1px solid ${theme.border}`,
    },
  }

  const dispatch = useDispatch()
  const user = useSelector((state: State) => {
    return state.user
  })
  const isProfileMenuOpen = useSelector((state: State) => {
    return state.ui.isProfileMenuOpen
  })

  const isDarkMode = useSelector((state: State) => {
    return state.ui.isDarkMode
  })

  const handleCreateWishlist = () => {
    handleCloseProfileMenu()
    dispatch({
      type: ACTION_TYPES.WISH_LIST__CREATE,
      payload: {
        user,
      },
    })
  }

  const handleOpenProfileMenu = () => {
    dispatch({
      type: ACTION_TYPES.UI__OPEN_PROFILE_MENU,
    })
  }

  const handleCloseProfileMenu = () => {
    dispatch({
      type: ACTION_TYPES.UI__CLOSE_PROFILE_MENU,
    })
  }

  const handleSignInRequest = () => {
    dispatch({
      type: ACTION_TYPES.AUTH__SIGN_IN_REQUEST,
    })
  }
  const handleSignOutRequest = () => {
    dispatch({
      type: ACTION_TYPES.AUTH__SIGN_OUT_REQUEST,
    })
  }

  const toggleDarkMode = () => {
    handleCloseProfileMenu()
    dispatch({
      type: ACTION_TYPES.UI__TOGGLE_DARK_MODE,
    })
  }

  return (
    <Div flex={true} ph={3} justifyContent="space-between" alignItems="center" style={styles.base}>
      <Div>
        <Link to="" bold={true} color={theme.text === '#000' ? 'black' : 'white'} mr={8}>
          YouWish
        </Link>
      </Div>

      <HasSignedIn>
        <Div>
          <Link to="lists" color={theme.text === '#000' ? 'black' : 'white'} mr={4}>
            Lister
          </Link>
          <Link to="people" color={theme.text === '#000' ? 'black' : 'white'}>
            Personer
          </Link>
        </Div>
      </HasSignedIn>

      <Div flex={true}>
        {user ? (
          <>
            <DropdownButton
              isOpen={isProfileMenuOpen}
              onOpen={handleOpenProfileMenu}
              onClose={handleCloseProfileMenu}
              text={user.displayName ? user.displayName.split(' ')[0] : 'John'}
              horizontalMenuPlacement="right"
            >
              <DropdownItem text="Min profil" to={`/people/${user.id}`} onClick={handleCloseProfileMenu} />
              <DropdownItem text="Ny ønskeseddel" onClick={handleCreateWishlist} />

              <DropdownItem text={`${isDarkMode ? 'Lyst' : 'Mørkt'} tema`} onClick={toggleDarkMode} />
              <DropdownItem text="Log ud" onClick={handleSignOutRequest} />
            </DropdownButton>
          </>
        ) : (
          <Button pill={true} onClick={handleSignInRequest}>
            Log ind
          </Button>
        )}
      </Div>
    </Div>
  )
}

export default withStyle(Navbar)
