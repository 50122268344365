import {Link} from '@reach/router'
import React from 'react'
import {ThemeContext} from 'theme'

import {withButton} from '../hocs'
import {border, color, font, prefix, space, withStyle} from '../style'

interface Props {
  type: string
  size: string
  pill: boolean
  square: boolean
  style: object
  block: boolean
  children: React.ReactNode
  to: string
  buttonEventHandlers: object
  disabled: boolean
  focused: boolean
  hover: boolean
  onClick(): void
}

const Button: React.FC<Props> = ({type = 'secondary', size = 'md', ...props}) => {
  const theme = React.useContext(ThemeContext)

  const width = props.block && '100%'

  const styles: any = {
    base: {
      fontFamily: font.family,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      // maxWidth: '100%',
      userSelect: 'none',
      borderStyle: border.style,
      borderWidth: 1,
      // border: 'none',
      borderRadius: props.pill ? '9999em' : space(),
      textDecoration: 'none',
      whiteSpace: 'noWrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      transition: 'all .1s ease-in-out',
      // fontWeight: bold && font.weight.bold,
      // boxShadow: '0 1.5px rgba(0,0,0,.5)',
    },

    primary: {
      backgroundColor: color.primary,
      color: color.white,
      borderColor: color.primary,

      hover: {
        backgroundColor: color.primaryDark,
        borderColor: color.primaryDark,
      },
    },

    secondary: {
      backgroundColor: theme.text,
      color: theme.background,
      borderColor: theme.background,

      hover: {
        borderColor: color.gray3,
      },
    },

    unstyled: {
      backgroundColor: 'transparent',
      color: color.white,
      paddingRight: 0,
      paddingLeft: 0,
      width: 'auto',
      boxShadow: 'none',
      border: 'none',
    },

    danger: {
      backgroundColor: color.white,
      color: color.primary,

      hover: {
        backgroundColor: color.primary,
        color: color.white,
      },
    },

    focused: {
      boxShadow: '0 0 0 3px rgba(0, 0, 0, .1)',
    },

    disabled: {
      cursor: 'not-allowed',
      opacity: 0.5,
    },

    sm: {
      fontSize: 12,
      height: space(6),
      width: props.square !== undefined ? space(6) : width,
      paddingRight: props.square === undefined && space(3),
      paddingLeft: props.square === undefined && space(3),
    },

    md: {
      fontSize: 16,
      height: space(9),
      width: props.square !== undefined ? space(9) : width,
      paddingRight: props.square === undefined && space(4),
      paddingLeft: props.square === undefined && space(4),
    },

    lg: {
      fontSize: 24,
      height: space(12),
      width: props.square !== undefined ? space(12) : width,
      paddingRight: props.square === undefined && space(8),
      paddingLeft: props.square === undefined && space(8),
    },
  }

  const Component = props.to ? Link : 'button'

  return (
    <Component
      {...props.buttonEventHandlers}
      onClick={props.onClick}
      to={props.to}
      style={prefix([
        styles.base,
        styles[size],
        styles[type],
        // isMuted && styles.muted,
        props.hover && !props.disabled && styles[type].hover,
        props.focused && styles.focused,
        // pressed && styles.pressed[type],
        // isActive && styles.active[type],
        // isSelected && styles.selected,
        props.disabled && styles.disabled,
        props.style,
      ])}
    >
      {props.children}
    </Component>
  )
}

export default withStyle(withButton(Button))
