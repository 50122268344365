import React from 'react'
import {font, withStyle} from '../style'

interface Styles {
  fontFamily: string
}

const styles: Styles = {
  fontFamily: font.family,
}

const H2 = (props: Props) => <h2 style={{...styles, ...props.style}}>{props.children}</h2>

interface Props {
  children: React.ReactNode
  style: object
}

export default withStyle(H2)
