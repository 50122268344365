import {Redirect, RouteComponentProps} from '@reach/router'
import dayjs from 'dayjs'
import React from 'react'
import {useSelector} from 'react-redux'
import {color, fontSize, space} from 'style'
import {CanEdit, Wish} from '../components'
import {Button, Div, H1} from '../elements'
import {WishList as IWishList} from '../interfaces'
import {orderByPosition} from '../utils'

interface State {
  wishLists: {
    items: {
      [id: string]: IWishList
    }
  }
}

const styles = {
  updatedAt: {
    marginTop: space(8),
    fontSize: fontSize(-2),
    color: color.gray9,
  },
}

const WishList: React.FC<RouteComponentProps<{id: string}>> = ({id}) => {
  const wishList = useSelector((state: State) => {
    if (!id || !state.wishLists || !state.wishLists.items[id]) {
      return null
    }
    return state.wishLists.items[id]
  })
  if (!wishList) {
    return <Redirect noThrow={true} to="lists" />
  }
  const {title, wishes, updatedAt} = wishList

  const orderedWishes = wishes ? orderByPosition(wishes) : []

  return (
    <>
      <Div flex={true} justifyContent="space-between" alignItems="center" mb={4}>
        <H1 m={0}>{title}</H1>
        <CanEdit wishList={wishList}>
          <Button pill={true} to={`/lists/${id}/edit`}>
            Ret
          </Button>
        </CanEdit>
      </Div>
      {orderedWishes.map((wish: any, index: number) => (
        <Wish key={index} wish={wish} />
      ))}
      {updatedAt && (
        <p style={styles.updatedAt}>
          Sidst opdateret for{' '}
          <abbr title={dayjs(updatedAt).format('dddd [d.] D. MMMM YYYY [kl.] HH:mm')}>
            {dayjs(updatedAt).fromNow()}
          </abbr>
        </p>
      )}
    </>
  )
}

export default WishList
