import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Select from 'react-select'
import ACTIONS_TYPES from '../actionTypes'
import {Button, Div, Input, Label, Modal} from '../elements'
import {People, Person, WishLists} from '../interfaces'

interface Props {
  id: string
  isOpen: boolean
  onClose(): void
}

interface SelectedOption {
  value: string
  label: string
}

interface State {
  wishLists: WishLists
  people: People
}

const styles = {
  select: {
    control: (provided: object) => ({...provided, minHeight: 36}),
  },
}

const EditWishListModal: React.FC<Props> = ({id, onClose, isOpen}) => {
  const dispatch = useDispatch()
  const [wishList, people] = useSelector((state: State) => {
    return [state.wishLists.items[id], state.people.items]
  })

  if (!wishList || !people) {
    throw new Error('No wish list or people found.')
  }

  const owner = people[wishList.owner]
  let editors: any[] = []

  if (wishList.editors && wishList.editors.length > 0) {
    editors = Object.keys(people)
      .map(key => people[key])
      .filter(person => wishList.editors && wishList.editors.includes(person.id))
  }

  const [title, setTitle] = useState(wishList.title)
  const [selectedEditor, setSelectedEditor] = useState<SelectedOption | null>(null)

  const updateTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }
  const editorsAvailable = Object.values(people)
    .filter((person: Person) => {
      const isOwner = person.id === owner.id
      const isEditor = editors.includes(person.id)
      return !isOwner && !isEditor
    })
    .map((person: Person) => {
      return {
        value: person.id,
        label: person.displayName,
      }
    })

  const closeModal = () => {
    onClose()
  }

  const saveTitleAndCloseModal = () => {
    dispatch({
      type: ACTIONS_TYPES.WISH_LIST__UPDATE_TITLE,
      payload: {
        wishListId: id,
        title,
      },
    })
    closeModal()
  }

  const handleAddEditor = () => {
    dispatch({
      type: ACTIONS_TYPES.WISH_LIST__ADD_EDITOR,
      payload: {
        wishListId: id,
        editorId: selectedEditor && selectedEditor.value,
      },
    })
    setSelectedEditor(null)
  }

  const deleteWishList = () => {
    const shouldDelete = window.confirm('Er du sikker på du vil slette denne liste?')
    if (shouldDelete) {
      dispatch({
        type: ACTIONS_TYPES.WISH_LIST__DELETE,
        payload: {
          id,
        },
      })
      closeModal()
    }
  }

  const handleSetSelectedEditor = (selectedOption: any) => {
    setSelectedEditor(selectedOption)
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeModal}
      verticalPosition="center"
      title="Ret ønskeseddel"
      leftActions={[{title: 'Slet liste', onClick: deleteWishList}]}
      actions={[
        {title: 'Annullér', onClick: closeModal},
        {title: 'Gem', type: 'primary', onClick: saveTitleAndCloseModal},
      ]}
    >
      <Label>Titel</Label>
      <Input value={title} type="text" mb={4} onChange={updateTitle} />

      <Label>Redaktører</Label>
      <Div flex={true} mb={4}>
        <Div flexGrow={1}>
          <Select
            styles={styles.select}
            value={selectedEditor}
            placeholder="Søg person..."
            options={editorsAvailable}
            onChange={handleSetSelectedEditor}
          />
        </Div>
        <Button disabled={selectedEditor === null} onClick={handleAddEditor}>
          Tilføj
        </Button>
      </Div>

      <Div flex={true} justifyContent="space-between" alignItems="center" mb={2}>
        <Div>{owner.displayName} (Ejer)</Div>
        <Div italic={true}>Kan ikke fjernes</Div>
      </Div>
      {editors.map((editor: any) => {
        const handleDeleteEditor = () => {
          dispatch({
            type: ACTIONS_TYPES.WISH_LIST__DELETE_EDITOR,
            payload: {wishListId: id, editorId: editor.id},
          })
        }
        return (
          <Div key={editor.id} flex={true} justifyContent="space-between" alignItems="center" mb={2}>
            <Div>{editor.displayName}</Div>
            <Button size="sm" onClick={handleDeleteEditor} tabIndex="-1">
              Fjern
            </Button>
          </Div>
        )
      })}
    </Modal>
  )
}

export default EditWishListModal
