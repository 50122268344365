import React from 'react'

export default WrappedComponent => {
  class WithButton extends React.Component {
    preventFocusTimer = null
    preventFocus = false

    constructor(props) {
      super(props)

      this.static = props.static

      this.state = {
        hover: false,
        pressed: false,
        focused: false,
      }
    }

    handleMouseEnter = event => {
      this.setState({hover: true})
      if (this.props.onMouseEnter) this.props.onMouseEnter(event)
    }

    handleMouseLeave = event => {
      this.setState({hover: false, pressed: false})
      if (this.props.onMouseLeave) this.props.onMouseLeave(event)
    }

    handleMouseDown = event => {
      this.preventFocus = true
      this.setState({pressed: true})
      if (this.props.onMouseDown) this.props.onMouseDown(event)
    }

    handleMouseUp = event => {
      if (this.preventFocusTimer) clearTimeout(this.preventFocusTimer)
      this.preventFocusTimer = setTimeout(() => {
        this.preventFocus = false
      }, 1000)

      this.setState({pressed: false})
      if (this.props.onMouseUp) this.props.onMouseUp(event)
    }

    handleFocus = event => {
      if (this.preventFocus) return
      this.setState({focused: true})
      if (this.props.onFocus) this.props.onFocus(event)
    }

    handleBlur = event => {
      this.setState({focused: false})
      if (this.props.onBlur) this.props.onBlur(event)
    }

    render() {
      const eventHandlers = {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        onMouseDown: this.handleMouseDown,
        onMouseUp: this.handleMouseUp,
        onFocus: this.handleFocus,
        onBlur: this.handleBlur,
      }

      const hover = this.static ? this.props.hover : this.state.hover
      const pressed = this.static ? this.props.pressed : this.state.pressed
      const focused = this.static ? this.props.focused : this.state.focused

      return (
        <WrappedComponent
          {...this.props}
          buttonEventHandlers={eventHandlers}
          hover={hover}
          pressed={pressed}
          focused={focused}
        />
      )
    }
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  WithButton.displayName = `withButton(${wrappedComponentName})`

  return WithButton
}
