// Utility for extracting size props from components

const size = ({fullWidth, fullHeight, width, height, maxWidth, minHeight, autoMargins}) => {
  const out = {}
  if (fullWidth) {
    out['width'] = '100%'
  }
  if (fullHeight) {
    out['height'] = '100%'
  }
  if (width) {
    out['width'] = width
  }
  if (height) {
    out['height'] = height
  }
  if (maxWidth) {
    out['maxWidth'] = maxWidth
  }
  if (minHeight) {
    out['minHeight'] = minHeight
  }
  if (autoMargins) {
    out['marginRight'] = 'auto'
    out['marginLeft'] = 'auto'
  }
  return out
}

export default size
