import {applyMiddleware, createStore} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from 'redux-devtools-extension'
import reducer from '../reducers/'

import rootSaga from 'sagas'
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))

sagaMiddleware.run(rootSaga)

export default store
