import mirrorCreator from 'mirror-creator'

export default mirrorCreator([
  'UI__OPEN_PROFILE_MENU',
  'UI__CLOSE_PROFILE_MENU',
  'UI__TOGGLE_DARK_MODE',

  'CURRENT_USER__DID_FETCH',

  'AUTH__SIGN_IN_REQUEST',
  'AUTH__SIGN_IN_SUCCESS',
  'AUTH__SIGN_IN_FAILURE',

  'AUTH__SIGN_OUT_REQUEST',
  'AUTH__SIGN_OUT_SUCCESS',
  'AUTH__SIGN_OUT_FAILURE',

  'WISH_LISTS__WILL_FETCH',
  'WISH_LISTS__DID_FETCH',

  'WISH_LIST__CREATE',
  'WISH_LIST__WILL_CREATE',
  'WISH_LIST__DID_CREATE',

  'WISH_LIST__SAVE',
  'WISH_LIST__WILL_SAVE',
  'WISH_LIST__DID_SAVE',

  'WISH_LIST__DELETE',
  'WISH_LIST__WILL_DELETE',
  'WISH_LIST__DID_DELETE',

  'WISH_LIST__ADD_EDITOR',
  'WISH_LIST__WILL_ADD_EDITOR',
  'WISH_LIST__DID_ADD_EDITOR',

  'WISH_LIST__DELETE_EDITOR',
  'WISH_LIST__WILL_DELETE_EDITOR',
  'WISH_LIST__DID_DELETE_EDITOR',

  'WISH_LIST__UPDATE_TITLE',

  'WISHES__MOVE_CURSOR_UP',
  'WISHES__MOVE_CURSOR_DOWN',

  'WISHES__REORDER',

  'WISHES__SET_ACTIVE',
  'WISHES__REMOVE_ACTIVE',
  'WISHES__CLEAR_ACTIVE',

  'WISHES__ADD_WISH',
  'WISHES__ADD_SECTION',
  'WISHES__ADD_WISH',
  'WISHES__REMOVE_WISH',
  'WISHES__REMOVE_WISH',

  'WISH__WILL_CREATE',
  'WISH__DID_CREATE',

  'WISH__WILL_REMOVE',
  'WISH__DID_REMOVE',

  'WISH__UPDATE_TITLE',
  'WISH__OPEN_MODAL',
  'WISH__CLOSE_MODAL',
  'WISH__UPDATE_DESCRIPTION',
  'WISH__UPDATE_URL',

  'PEOPLE__WILL_FETCH',
  'PEOPLE__DID_FETCH',
])
