function getIsSection(title: string) {
  if (!title) {
    return false
  }
  if (title.slice(-1) === ':') {
    return true
  }
  return false
}

export default getIsSection
