let semaphore = 0
const noScrollClassName = 'no-scroll'

function increase() {
  semaphore++
  updateNoScrollClassOnBody()
}

function decrease() {
  semaphore--
  updateNoScrollClassOnBody()
}

function updateNoScrollClassOnBody() {
  if (semaphore > 0) {
    addNoScrollClassToBody()
  } else {
    removeNoScrollClassFromBody()
  }
}

function addNoScrollClassToBody() {
  const {body} = document
  if (body.className.indexOf(noScrollClassName) === -1) {
    body.className = `${body.className} ${noScrollClassName}`
  }
}

function removeNoScrollClassFromBody() {
  const {body} = document
  body.className = body.className.replace(noScrollClassName, '')
}

export default {
  increase,
  decrease,
}
