import React from 'react'
import {compose} from 'recompose'
import {space, color, withStyle, prefix} from 'style'
import {withButton} from 'hocs'
import {Div} from 'elements'
import {Link} from '@reach/router'

const DropdownItem = props => {
  const {
    type,
    text,
    hover = false,
    pressed = false,
    active = false,
    selected = false,
    disabled = false,
    buttonEventHandlers,
    to,
    onClick,
    style,
  } = props

  const handleClick = () => {
    if (disabled) return
    if (onClick) onClick()
  }

  const styles = {
    base: {
      cursor: 'pointer',
      outline: 'inherit',
      font: 'inherit',
      border: 'none',
      backgroundColor: 'transparent',
      textAlign: 'left',
      display: 'block',
      userSelect: 'none',
      color: color.text,
      transition: 'background-color .15s ease-in-out',
      width: '100%',
      paddingTop: space(2),
      paddingRight: space(4),
      paddingBottom: space(2),
      paddingLeft: space(4),
    },
    selected: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      color: color.text,
      cursor: 'default',
    },
    hover: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    pressed: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    active: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    disabled: {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  }

  const ElementType = to ? Link : 'button'

  return (
    <ElementType
      {...buttonEventHandlers}
      onClick={handleClick}
      to={to}
      style={prefix([
        styles.base,
        hover && styles.hover,
        pressed && styles.pressed,
        active && styles.active,
        selected && styles.selected,
        disabled && styles.disabled,
        type === 'danger' && styles.danger,
        type === 'success' && styles.success,
        style,
      ])}
    >
      <Div flex alignItems="center">
        {text}
      </Div>
    </ElementType>
  )
}

export default compose(
  withStyle,
  withButton,
)(DropdownItem)
