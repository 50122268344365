import ACTIONS from 'actions'
import {all, call, fork, put} from 'redux-saga/effects'
import {rsf} from './firebase'

function* fetchPeopleSaga() {
  yield put(ACTIONS.PEOPLE__WILL_FETCH())

  const snapshot = yield call(rsf.firestore.getCollection, 'users')
  let users
  snapshot.forEach(user => {
    users = {
      ...users,
      [user.id]: user.data(),
    }
  })

  Object.keys(users).forEach(key => {
    users[key].id = key
  })

  yield put(ACTIONS.PEOPLE__DID_FETCH({people: users}))
}

export default function* peopleSaga() {
  yield all([fork(fetchPeopleSaga)])
}
