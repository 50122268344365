import React from 'react'
import {Button} from 'elements'
import {prefix, space, border} from 'style'

const ModalHeader = props => {
  const styles = {
    base: {
      position: 'relative',
      borderTopLeftRadius: border.radius,
      borderTopRightRadius: border.radius,
      paddingTop: space(4),
      paddingRight: space(4),
      paddingBottom: space(4),
      paddingLeft: space(4),
      borderBottom: '1px solid #ddd',
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: 700,
    },
    closeButton: {
      position: 'absolute',
      top: space(2),
      right: space(2),
    },
  }

  return (
    <div style={prefix([styles.base])}>
      {props.onDismiss && (
        <Button square onClick={props.onDismiss} style={styles.closeButton}>
          ×
        </Button>
      )}
      <div style={prefix([styles.title])}>{props.title}</div>
    </div>
  )
}

export default ModalHeader
