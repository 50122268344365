import React from 'react'
import {withStyle} from '../style'

const Img = (props: Props) => <img src={props.src} alt={props.alt || ''} style={props.style} />

interface Props {
  src: string
  alt: string
  style: object
}

export default withStyle(Img)
