import ACTION_TYPES from 'actionTypes'

const user = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.AUTH__SIGN_IN_SUCCESS: {
      const {user} = action.payload
      return user ? user : null
    }

    case ACTION_TYPES.AUTH__SIGN_OUT_SUCCESS: {
      return null
    }

    default: {
      return state
    }
  }
}

export default user
