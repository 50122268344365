// The globalComponentStack is used to keep track of components put on top of each other.
// It listens for the Escape-key pressed event and notifies the top most component when it happens.
//
// The component being added to the stack can implement the following methods:
//
// - onGlobalComponentStackDidReceiveEscapeForThisComponent
// - onGlobalComponentStackDidAddThisComponent
// - onGlobalComponentStackDidRemoveThisComponent

let keyPressHandler = null
let stack = []

const handleKeydown = e => {
  if (e.key === 'Escape' || e.key === 'Esc') {
    const topMostComponent = stack[stack.length - 1]
    if (topMostComponent.onGlobalComponentStackDidReceiveEscapeForThisComponent)
      topMostComponent.onGlobalComponentStackDidReceiveEscapeForThisComponent()
  }
}

const globalComponentStack = {
  add: component => {
    stack = [...stack, component]
    if (component.onGlobalComponentStackDidAddThisComponent) component.onGlobalComponentStackDidAddThisComponent()
    if (!keyPressHandler) {
      keyPressHandler = window.addEventListener('keydown', handleKeydown)
    }
  },
  remove: component => {
    stack = stack.filter(c => c !== component)
    if (component.onGlobalComponentStackDidRemoveThisComponent) component.onGlobalComponentStackDidRemoveThisComponent()
    if (stack.length === 0) {
      window.removeEventListener('keydown', handleKeydown)
      keyPressHandler = null
    }
  },
}

export default globalComponentStack
