import ACTION_TYPES from 'actionTypes'

const initialState = {
  isProfileMenuOpen: false,
  isDarkMode: false,
}

interface State {
  isProfileMenuOpen?: boolean
  isDarkMode?: boolean
}

interface UIAction {
  type: string
}

const ui = (state: State = initialState, action: UIAction) => {
  switch (action.type) {
    case ACTION_TYPES.UI__OPEN_PROFILE_MENU:
      return {
        ...state,
        isProfileMenuOpen: true,
      }

    case ACTION_TYPES.UI__CLOSE_PROFILE_MENU:
      return {
        ...state,
        isProfileMenuOpen: false,
      }

    case ACTION_TYPES.UI__TOGGLE_DARK_MODE:
      return {
        ...state,
        isDarkMode: !state.isDarkMode,
      }

    default: {
      return state
    }
  }
}

export default ui
