import React from 'react'
import {withStyle} from '../style'

const Div = (props: Props) => (
  <div style={props.style} className={props.className}>
    {props.children}
  </div>
)

interface Props {
  style: object
  className: string
  children: React.ReactNode
}

export default withStyle(Div)
