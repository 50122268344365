import React from 'react'
import {ModalBase, ModalContainer, ModalHeader, ModalBody, ModalFooter} from './'

const Modal = props => {
  if (!props.isOpen) return null

  const {disabled} = props

  return (
    <ModalBase
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      dismissOnEscape={props.dismissOnEscape}
      dismissOnClickOutside={props.dismissOnClickOutside}
      style={props.style}
      width={props.width}
    >
      <ModalContainer
        disabled={disabled}
        width={props.width}
        verticalPosition={props.verticalPosition}
        horizontalPosition={props.horizontalPosition}
        minHeight={props.minHeight}
      >
        {props.renderHeader !== undefined ? (
          props.renderHeader && props.renderHeader()
        ) : (
          <ModalHeader
            type={props.type}
            title={props.title}
            icon={props.icon}
            id={props.titleId}
            values={props.titleValues}
            onDismiss={props.onDismiss}
            disabled={disabled}
            hideCloseButton={props.hideCloseButton}
          />
        )}
        {props.renderBody ? (
          props.renderBody()
        ) : (
          <ModalBody disabled={disabled} type={props.type}>
            {props.children}
          </ModalBody>
        )}
        {props.renderFooter ? (
          props.renderFooter()
        ) : (
          <ModalFooter
            leftActions={props.leftActions}
            rightActions={props.rightActions}
            actions={props.actions}
            disabled={disabled}
          />
        )}
      </ModalContainer>
    </ModalBase>
  )
}

export default Modal
