import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import ReduxSagaFirebase from 'redux-saga-firebase'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAYWAUVHC-peXD8G4AjOuRVfng5EKNzeSI',
  authDomain: 'youwish-next.firebaseapp.com',
  databaseURL: 'https://youwish-next.firebaseio.com',
  projectId: 'youwish-next',
})

const rsf = new ReduxSagaFirebase(firebaseApp)

const db = firebase.firestore()

export {firebase, rsf, db}
