import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {color, prefix, withStyle, zIndex} from 'style'
import {globalComponentStack, noScrollSemaphore} from 'utils'

const overlayRoot = () => document.getElementById('overlay-root')

class Overlay extends Component {
  el = document.createElement('div')
  componentDidMount() {
    overlayRoot().appendChild(this.el)
    globalComponentStack.add(this)
  }

  onGlobalComponentStackDidReceiveEscapeForThisComponent() {
    if (this.props.isDimissOnEscEnabled) {
      globalComponentStack.remove(this)
      this.props.onDismiss()
    }
  }

  onGlobalComponentStackDidAddThisComponent() {
    if (this.props.isDisableScrollEnabled) {
      noScrollSemaphore.increase()
    }
  }

  onGlobalComponentStackDidRemoveThisComponent() {
    if (this.props.isDisableScrollEnabled) {
      noScrollSemaphore.decrease()
    }
  }

  componentWillUnmount() {
    overlayRoot().removeChild(this.el)
    globalComponentStack.remove(this)
  }

  render() {
    const {onDismiss, isBackgroundTransparent} = this.props

    const styles = {
      base: {
        position: 'fixed',
        zIndex: zIndex.overlay,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      visibleBackground: {
        backgroundColor: color.black,
        opacity: 0.5,
      },
    }

    const component = (
      <div onClick={onDismiss} style={prefix([styles.base, !isBackgroundTransparent && styles.visibleBackground])} />
    )
    return ReactDOM.createPortal(component, this.el)
  }
}

export default withStyle(Overlay)
