import {Redirect} from '@reach/router'
import React from 'react'
import {useSelector} from 'react-redux'
import {Person, WishList} from '../interfaces'

interface CanEditProps {
  wishList: WishList
  children?: any
  redirectTo?: string
}

interface State {
  user: Person
}

const CanEdit: React.FC<CanEditProps> = ({wishList, redirectTo, children}) => {
  const userId = useSelector((state: State) => {
    return state.user && state.user.id
  })

  if (!wishList || !wishList.owner || !userId) {
    return null
  }

  const isOwner = wishList.owner === userId
  const isEditor = wishList.editors && wishList.editors.includes(userId)

  const canEdit = isOwner || isEditor

  if (!canEdit) {
    if (redirectTo) {
      return <Redirect noThrow={true} to={redirectTo} />
    }
    return null
  }
  return children
}

export default CanEdit
