import {RouteComponentProps} from '@reach/router'
import {HasSignedIn} from 'components'
import {H1, Link} from 'elements'
import React from 'react'
import {useSelector} from 'react-redux'
import {WishLists as IWishLists} from '../interfaces'

interface State {
  wishLists: IWishLists
}

const WishLists: React.FC<RouteComponentProps> = () => {
  const wishLists = useSelector((state: State) => {
    const items = state.wishLists.items
    if (!items) {
      return null
    }
    return Object.keys(items)
      .map(key => items[key])
      .sort((a, b) => {
        if (!a.title || !b.title) {
          return -1
        }
        return a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1
      })
  })

  const styles = {
    list: {
      listStyleType: 'none',
      paddingLeft: 0,
    },
    link: {
      display: 'block',
    },
  }

  if (!wishLists) {
    return null
  }

  return (
    <HasSignedIn>
      <H1 m={0}>Ønskesedler</H1>
      <ul style={styles.list}>
        {wishLists.map(({id, title}) => (
          <li key={`wish-${id}`}>
            <Link to={`/lists/${id}`} pv={1} className="link-complex" style={styles.link}>
              <span className="link-complex-target">{title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </HasSignedIn>
  )
}

export default WishLists
