// Utility for extracting radii props from components
import {border} from 'style'

const radii = ({borderRadius, circle}) => {
  if (borderRadius) {
    return {
      borderRadius: typeof borderRadius === 'boolean' ? border.radius : borderRadius,
    }
  } else if (circle) {
    return {
      borderRadius: 99999,
    }
  } else {
    return null
  }
}

export default radii
