import getIsSection from './getIsSection'

const getHasSections = (wishes: Wishes): boolean => {
  if (!wishes) {
    return false
  }
  let hasSections = false
  Object.keys(wishes)
    .map(key => wishes[key])
    .forEach(wish => {
      if (getIsSection(wish.title)) {
        hasSections = true
      }
    })
  return hasSections
}

interface Wishes {
  [key: string]: {
    title: string
  }
}

export default getHasSections
