import React from 'react'
import stylesFromProps from './utils'
import {prefix} from './inlineStyle'

const withStyle = Component => {
  const ComponentWithStyle = props => {
    const styleProps = [
      // Margin
      'm', // All
      'mh', // Horizontal
      'mv', // Vertical
      'mt', // Top
      'mr', // Right
      'mb', // Bottom
      'ml', // Left

      // Padding
      'p', // All
      'ph', // Horizontal
      'pv', // Vertical
      'pt', // Top
      'pr', // Right
      'pb', // Bottom
      'pl', // Left

      // Flex
      'flex',
      'flexWrap',
      'wrap',
      'alignItems',
      'justifyContent',
      'flexDirection',
      'column',
      'order',
      'flexGrow',

      // Grid
      'grid',
      'gridTemplateColumns',
      'gridTemplateRows',
      'gridTemplateAreas',
      'justifyItems',
      'placeItems',
      'alignContent',
      'gridArea',
      'gridColumn',
      'gridRow',
      'gridAutoColumns',
      'gridAutoRows',
      'gridAutoFlow',
      'justifySelf',
      'alignSelf',
      'placeSelf',

      // Border
      'b',
      'bh',
      'bv',
      'bt',
      'br',
      'bb',
      'bl',

      // Size
      'fullWidth',
      'fullHeight',
      'width',
      'height',
      'maxWidth',
      'autoMargins',

      // Font
      'fontSize',
      'muted',
      'bold',
      'italic',
      'center',
      'right',
      'left',

      // Color
      'color',
      'bg',
      'borderColor',

      // Radii
      'borderRadius',
      'circle',
    ]
    const filteredProps = Object.entries(props).reduce((acc, [key, value]) => {
      if (!styleProps.includes(key)) {
        acc[key] = value
      }
      return acc
    }, {})

    return <Component {...filteredProps} style={prefix([stylesFromProps(props), props.style])} />
  }
  return ComponentWithStyle
}

export default withStyle
