import {RouteComponentProps} from '@reach/router'
import React from 'react'
import {Div} from '../elements'

const FrontPage = (props: RouteComponentProps) => (
  <Div flex={true} fullHeight={true} justifyContent="center" alignItems="center" italic={true}>
    <Div>Må alle dine ønsker gå i opfyldelse!</Div>
  </Div>
)

export default FrontPage
