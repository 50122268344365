import React from 'react'

import {prefix, color, space} from 'style'

import {Div} from 'elements'

const ModalContainer = props => {
  const modalMargin = space(4)

  const width = props.width || 480 + modalMargin * 2

  const styles = {
    outer: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'row',
      maxHeight: `calc(100% - ${modalMargin * 2}px)`,
      width: width,
    },
    'center center': {
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    'top center': {
      left: '50%',
      top: modalMargin,
      transform: 'translate(-50%, 0)',
    },
    base: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: color.white,
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.25)',
      width: '100%',
      maxHeight: `calc(100vh - ${modalMargin * 2}px)`, // IE fix (use vh)
      minHeight: props.minHeight,
    },
  }

  const positionStyle =
    styles[`${props.verticalPosition || 'top'} ${props.horizontalPosition || 'center'}`] || styles['center center']

  return (
    <div style={prefix([styles.outer, positionStyle])}>
      <Div borderRadius style={prefix([styles.base, props.style])}>
        {props.children}
      </Div>
    </div>
  )
}

export default ModalContainer
