import React from 'react'
import {font, withStyle} from '../style'

interface Styles {
  fontFamily: string
}

const styles: Styles = {
  fontFamily: font.family,
}

const H1 = (props: Props) => <h1 style={{...styles, ...props.style}}>{props.children}</h1>

export default withStyle(H1)

interface Props {
  children: React.ReactNode
  style: object
}
