const flex = ({flex, flexWrap, wrap, alignItems, justifyContent, flexDirection, column, order, flexGrow}) => {
  const style = {}

  if (flex) {
    style['display'] = 'flex'
  }
  if (flexWrap) {
    style['flexWrap'] = 'wrap'
  }
  if (wrap) {
    style['flexWrap'] = wrap
  }
  if (flexDirection) {
    style['flexDirection'] = flexDirection
  }
  if (column) {
    style['flexDirection'] = 'column'
  }
  if (alignItems) {
    style['alignItems'] = alignItems
  }
  if (justifyContent) {
    style['justifyContent'] = justifyContent
  }
  if (order) {
    style['order'] = order
  }
  if (flexGrow) {
    style['flexGrow'] = flexGrow
  }
  return style
}

export default flex
