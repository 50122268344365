import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {withStyle, zIndex, border, color, prefix} from 'style'
import Popper from 'popper.js'
import {Div} from 'elements'

const overlayRoot = () => document.getElementById('overlay-root')

const placementMapping = {
  'top left': 'top-start',
  'top center': 'top',
  'top right': 'top-end',
  'bottom left': 'bottom-start',
  'bottom center': 'bottom',
  'bottom right': 'bottom-end',
}

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.dropdownMenuRef = React.createRef()
    this.popper = null
    this.state = {
      top: 0,
      left: 0,
    }
  }

  componentDidMount() {
    overlayRoot().appendChild(this.el)

    const button = this.props.buttonRef.current
    const popper = this.dropdownMenuRef.current

    if (button && popper) {
      const placement = placementMapping[`${this.props.verticalMenuPlacement} ${this.props.horizontalMenuPlacement}`]
      this.popper = new Popper(button, popper, {
        placement,
        modifiers: {
          flip: {
            enabled: false,
          },
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      })
    }
  }

  componentWillUnmount() {
    if (this.popper) this.popper.destroy()
    overlayRoot().removeChild(this.el)
  }

  render() {
    const styles = {
      outer: {
        zIndex: zIndex.overlay,
      },
      menu: {
        boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
        backgroundColor: color.white,
        borderStyle: border.style,
        borderWidth: border.width,
        borderRadius: border.radius,
      },
      menuItemsContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'touch',
        maxHeight: 600,
      },
      secondary: {
        backgroundColor: color.white,
        borderColor: color.grayC,
      },
      primary: {
        borderColor: color.brand,
      },
      danger: {
        borderColor: color.red,
      },
      transparent: {
        borderColor: border.color,
      },
      unstyled: {
        borderColor: border.color,
      },
    }
    const component = (
      <div ref={this.dropdownMenuRef} style={prefix([styles.outer, this.props.style])}>
        {this.props.renderMenu ? (
          this.props.renderMenu()
        ) : (
          <div style={prefix([styles.menu, styles[this.props.type]])}>
            {this.props.renderNonClickableHeader && this.props.renderNonClickableHeader()}
            {this.props.children && (
              <Div
                pv={2}
                onClick={this.props.closeOnSelect ? this.props.onClose : undefined}
                style={prefix(styles.menuItemsContainer)}
              >
                {this.props.children}
              </Div>
            )}
          </div>
        )}
      </div>
    )
    return ReactDOM.createPortal(component, this.el)
  }
}

export default withStyle(DropdownMenu)
